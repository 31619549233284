const test = {
    api: 'testapi.qmforge.net',
    stripeKey: 'pk_test_OCn6ed5ZrjWKlpTFQ31eRJjS'
};

const prod = {
    api: 'api.qmforge.net',
    stripeKey: 'pk_live_yZB1gqff4nHOueH1wh1jp4Q7'
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : test;

export default config;
