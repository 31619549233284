import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import handleDownload from "./handleDownload";
import ExpiredLink from "./ExpiredLink";
import {Redirect} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const OrderConfirmationBody = (props) => {
    const { item, orderId, expireDate, setStatus } = props;
    const [ downloading, setDownloading ] = useState(false);

    const download = (orderId, setStatus) => {
        setDownloading(true);
        handleDownload(orderId, setStatus);
        setTimeout(() => setDownloading(false), 5000);
    };

    return (
        <Container className={'mt-5'} >
            <Card>
                <Card.Header>
                    <h2>Order Confirmation</h2>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        Thanks for purchasing QMForge {item.version} for {item.os}!
                    </Card.Title>
                    <Card.Text>
                        Your order number is {orderId}. You can download QMForge {item.downloads} times before your
                        order expires on {expireDate}.
                    </Card.Text>
                    <Card.Text>
                        If you need help or have questions, please contact <a href={'emailto:support@qmforge.net'}>
                        support@qmforge.net</a>.
                    </Card.Text>
                    <Button variant={'primary'} onClick={() => download(orderId, setStatus)} disabled={downloading}>
                        {downloading
                            ? <Spinner animation={'border'} />
                            : 'Download'
                        }
                    </Button>
                </Card.Body>
            </Card>
        </Container>
    )
}

const OrderConfirmation = (props) => {
    const { item, orderId, expireDate } = props;
    const [ status, setStatus ] = useState();

    return (
        {
            403: <ExpiredLink orderId={orderId} />,
            404: <Redirect to={'/error'} />,
        }[status] ?? <OrderConfirmationBody item={item} orderId={orderId} expireDate={expireDate} setStatus={setStatus}/>
    );
}

export default OrderConfirmation;
