import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

const CheckoutHeader = ({ item }) => (
    <Modal.Header closeButton>
        <Container>
            <Row>
                <Col xs={6} md={2}>
                    <img src={`/icons/${item?.os}.png`} alt={`${item?.os} icon`} width={'100%'} />
                </Col>
                <Col xs={12} md={10}>
                    <h4 className={'pt-2'}>QMForge {item?.version}</h4>
                </Col>
            </Row>
        </Container>
    </Modal.Header>
);

export default CheckoutHeader;
