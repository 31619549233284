import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import ExpiredLink from "./ExpiredLink";
import {Redirect} from "react-router-dom";
import handleDownload from "./handleDownload";

const DirectDownloadBody = (props) => {
    const { orderId } = props;
    return (
        <Container className={'mt-5'}>
            <Card>
                <Card.Header>
                    <h3>Preparing Download</h3>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        Your order <b>{orderId}</b> should begin downloading shortly.
                    </Card.Text>
                    <Card.Text>
                        Please contact <a href="emailto:support@qmforge.net">support@qmforge.net</a> if
                        you need further assistance.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}
const DirectDownload = (props) => {
    const { orderId } = props;
    const [ status, setStatus ] = useState();

    useEffect(() => {
        handleDownload(orderId, setStatus)
    }, [orderId]);

    return (
        {
            403: <ExpiredLink orderId={orderId} />,
            404: <Redirect to={'/error'} />,
        }[status] ?? <DirectDownloadBody orderId={orderId} />
    );
}

export default DirectDownload;
