import Form from "react-bootstrap/Form";

const CheckoutItem = (props) => {
    const { errors, register, type, defaultValue, defaultError } = props;

    return (
        <Form.Group className="mb-3" controlId={`form${defaultValue}`}>
            <Form.Control placeholder={defaultValue}
                          type={'text'}
                          {...register(type, {required: true})}
                          isInvalid={!!errors[type]}
            />
            {errors[type] &&
            <Form.Control.Feedback type="invalid">
                {errors[type].message || defaultError}
            </Form.Control.Feedback>
            }
        </Form.Group>
    )
};

export default CheckoutItem;
