import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

const Citation = (props) => (
    <Container className={'px-5'}>
        <Alert className={'mx-5'} variant={'info'}>
            <p>
                QMForge can be cited however you feel appropriate, although I
                recommend ONE of the following:
            </p>
            <ul>
                <li>Tenderholt, Adam L. <span style={{fontStyle: 'italic'}}>QMForge</span>, Version {props.version}, https://qmforge.net.</li>
                <li>Tenderholt, Adam L. "QMForge: A Program to Analyze Quantum Chemistry Calculations", Version {props.version}, https://qmforge.net.</li>
            </ul>
            <p>
                Of course, citation requirements vary from journal to journal,
                so adjust the above as necessary.
            </p>
        </Alert>
    </Container>

);

export default Citation;