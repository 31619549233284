import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Screenshot(props, ref) {
    return (
        <Carousel.Item {...props} ref={ref}>
            <figure className="figure d-block">
                <img className="figure-img d-block w-75 mx-auto"
                     src={props.item.src}
                     alt={props.item.text} />
                    <figcaption className="figure-caption text-center text-light">
                        {props.item.text}
                    </figcaption>
            </figure>
        </Carousel.Item>
    )
}

export default React.forwardRef(Screenshot);
