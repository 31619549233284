import Container from 'react-bootstrap/Container';

const Footnote = () => (
    <Container className={'text-center pt-3 pb-5'}>
        <small className={'text-muted'}>
            Adam Tenderholt<br/>
            webmaster@qmforge.net<br/>
        </small>
    </Container>
)

export default Footnote;
