import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Product from './Product';
import PlaceholderProduct from './PlaceholderProduct';



const Products = (props) => {
    if (props.products.processing) {
        const products = ['mac', 'windows'].map((os) => (<Col key={os}><PlaceholderProduct/></Col>));
        return (<Container className={'p-5'}><Row className={'px-5 mx-5'}>{products}</Row></Container>);
    }

    const { items } = props.products;
    const products = items.map((product) => (<Col key={product.key}><Product product={product} setBuying={props.setBuying}/></Col>))

    return (
        <>
            {products.length > 0
                ? <Container className={'p-5'}><Row className={'px-5 mx-5'}>{products}</Row></Container>
                : <Container>
                    <Alert variant={'danger'}>
                        Unable to find products.
                            Please email <a href={'mailto:webmaster@qmforge.net'}>webmaster@qmforge.net</a>.
                    </Alert>
                </Container>}
        </>
    );
};

export default Products;