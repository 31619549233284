import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';

import Screenshot from './Screenshot';

const screenshots = [
    {
        src: '/screenshots/win/fragment_assignment.png',
        text: 'Windows: Assigning fragments for population analysis',
    },
    {
        src: '/screenshots/mac/mulliken_analysis.png',
        text: 'Mac OS X: Results from Mulliken population analysis',
    },
    {
        src: '/screenshots/win/lowdin_analysis.png',
        text: 'Windows: Results from Löwdin population analysis'
    },
    {
        src: '/screenshots/mac/gross_population.png',
        text: 'Mac OS X: Results from gross population analysis'
    },
    {
        src: '/screenshots/win/geometry_optimization.png',
        text: 'Windows: Analyzing a geometry optimization'
    },
    {
        src: '/screenshots/mac/bond_orders.png',
        text: 'Mac OS X: Results from a Mayer bond orders analysis',
    },
    {
        src: '/screenshots/win/scan_coordinate.png',
        text: 'Windows: Analyzing a relaxed geometry optimization (i.e. scan coordinate)'
    },
    {
        src: '/screenshots/mac/cartesian_editor.png',
        text: 'Mac OS X: Simple Cartesian coordinate editor'
    },
    {
        src: '/screenshots/win/frequency.png',
        text: 'Windows: Analyzing frequency calculation'
    },
    {
        src: '/screenshots/mac/absorption_spectrum.png',
        text: 'Mac OS X: Analyzing a TD/DFT calculation'
    }
]

function Screenshots(props) {
    return (
        <Container fluid className={'bg-dark d-block'}>
            <Carousel className={'pt-4 mb-3 w-75 mx-auto'} indicators={false}>
                {screenshots.map((item) => <Screenshot item={item} key={item.text}/>)}
            </Carousel>
        </Container>

    )
}

export default Screenshots;