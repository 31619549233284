import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Product = (props) => {
    const { product } = props;
    const { price, os, version } = product;

    return (
        <Card className={'bg-light mb-3 text-center'}>
            <Card.Img variant={'top'} className={'mx-auto pt-2'} src={`/icons/${os}.png`} alt={`${os} icon`} style={{width: '100px'}}/>
            <Card.Body>
                <Card.Title>QMForge {version}<br/> for {os}</Card.Title>
                <Card.Text>${parseInt(price) / 100}</Card.Text>
                <Button onClick={() => props.setBuying(product)}>
                    Buy
                </Button>
            </Card.Body>
        </Card>
    );
}

export default Product;
