import Form from "react-bootstrap/Form";
import { CardElement } from "@stripe/react-stripe-js";

const CheckoutCard = ({ stripeError }) => (
    <Form.Group className="mb-3">
        <Form.Control as={CardElement} isInvalid={!!stripeError}/>
        {stripeError &&
        <Form.Control.Feedback type={'invalid'}>
            {stripeError || 'There is a problem with your credit card info'}
        </Form.Control.Feedback>
        }
    </Form.Group>
);

export default CheckoutCard;
