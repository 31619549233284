import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const CheckoutButton = (props) => {
    const { price, processing } = props;
    
    return (
        <Button type={'submit'} disabled={processing}>
            {processing
                ? <Spinner animation={'border'} />
                : `Pay $${price / 100}`
            }
            
        </Button>
    )
};

export default CheckoutButton;
