import {
    HashRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './App.css';
import Download from './Download';
import Main from './Main';
import config from './config';
import NotFound from "./NotFound";

const stripePromise = loadStripe(config.stripeKey);

const App = () => {
    return (
        <Elements stripe={stripePromise}>
            <Router>
                <Switch>
                    <Route path={"/"} exact>
                        <Main />
                    </Route>
                    <Route path={"/download/:orderId"}
                           render={(props) => <Download {...props} />}
                    />
                    <Route path={"/"}>
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </Elements>
    );
}

export default App;
