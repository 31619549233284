import { useParams } from "react-router-dom";
import DirectDownload from "./download/DirectDownload";
import OrderConfirmation from "./download/OrderConfirmation";

const Download = (props) => {
    console.log('Using props', props);
    const { orderId } = useParams();
    return props.location?.state
        ? (<OrderConfirmation orderId={orderId}
                              item={props.location?.state.item}
                              expireDate={props.location?.state.expireDate} />)
        : (<DirectDownload orderId={orderId} />)
};

export default Download;
