import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

const History = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Container className={'text-center pt-5'}>
                <Button variant={'link'} onClick={handleShow} className={'text-muted'}>
                    History
                </Button>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            QMForge is the direct descendent of PyMOlyze, which was inspired by
                            the console-based, (originally) Windows-only program <a
                            href="http://www.sg-chem.net/">AOMix</a> that has features including
                            (and in addition to) those listed above. I initially wrote PyMOlyze in
                            graduate school because I wanted a user-friendly (ie. graphical) Linux
                            program to do MPA for each molecular orbital of Gaussian calculations.
                        </p>
                        <p>QMForge was eventually extended it to handle files from geometry
                            optimizations because I found myself wishing I could translate and
                            rotate the optimized structure and save that as an XYZ file. During my
                            Ph.D. program, I used QMForge for almost everything except making
                            molecular orbital pictures and diagrams. Now that I've moved
                            on, it is more a hobby project. It now handles frequency and excited
                            state calculations.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    );
}

export default History;