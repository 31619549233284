import { useEffect, useState } from "react";
import axios from "axios";

import Screenshots from "./main/Screenshots";
import Description from "./main/Description";
import Products from "./main/Products";
import Checkout from "./main/Checkout";
import Citation from "./main/Citation";
import History from "./main/History";
import Footnote from "./main/Footnote";
import config from './config';

const Main = () => {
    const [ products, setProducts ] = useState({ processing: true });
    const [ buying, setBuying ] = useState(undefined);

    useEffect(() => {
        axios.get(`https://${config.api}/products`)
            .then((result) => {
                    setProducts({ processing: false, items: result.data })
                },
                (error) => {
                    console.error("Unable to fetch products", error)
                    setProducts({ processing: false, items: [] })
                })
    }, [products.processing]);

    return (
        <>
            <Screenshots />
            <Description />
            <Products products={products} setBuying={setBuying} />
            <Checkout buying={buying} setBuying={setBuying} />
            <Citation version={'3.2'} />
            <History />
            <Footnote />
        </>
    )
};

export default Main;
