import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

const ExpiredLink = (props) => {
    const { orderId } = props;

    return (
        <Container className={'mt-5'}>
            <Card border={'danger'}>
                <Card.Header style={{color: '#ffffff', backgroundColor: '#dc3545'}}>
                    <h3>Expired Link</h3>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        The link for order <b>{orderId}</b> has expired.
                    </Card.Text>
                    <Card.Text>
                        Please contact <a href="emailto:support@qmforge.net">support@qmforge.net</a> if
                        you need further assistance.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    )
};

export default ExpiredLink;
