import Container from 'react-bootstrap/Container';

const Description = () => (
    <Container className={'py-4 px-5'}>
        <p className='lead'>
            QMForge is a program used to analyze the results of quantum chemistry
            (DFT) calculations.
        </p>
        <p>
            Output log files from Gaussian 98/03/09/16, ADF, GAMESS (US), GAMESS (UK),
            PC-GAMESS, Jaguar, NWChem, ORCA, QChem, and others are supported
            (<a href="https://cclib.github.io/#summary">details</a>). The
            following analyses are available:
        </p>

        <ul>
            <li>Mulliken Population Analysis (MPA)</li>
            <li>C-squared Population Analysis (CSPA)</li>
            <li>L&ouml;wdin Population Analysis (LPA)</li>
            <li>Overlap Population Analysis (OPA)</li>
            <li>Mayer's Bond Orders</li>
            <li>Charge Decomposition Analysis (CDA)</li>
            <li>Fragment Analysis</li>
        </ul>

        <p>MPA, CSPA, LPA, and OPA require a user-defined set of fragments,
            and prints the percent contributions those fragments make towards each
            molecular orbital in the molecule. Mayer's bond orders calculates the
            bond orders between each pair of atoms in the molecule. CDA and
            Fragment Analysis require three or more calculations (one for the
            entire molecule, and two fragment calculations), and provide
            information about the bonding interactions between fragments.</p>

        <p>QMForge can also be used to:</p>
        <ul>
            <li>Extract the steps along a geometry optimization,</li>
            <li>Extract the steps from a relaxed geometry optimization (i.e. scan coordinate),</li>
            <li>Animate the Normal Modes of a frequency calculation and save
                as an animated GIF,
            </li>
            <li>Plot and save the energies/intensities of a frequency
                calculation, and
            </li>
            <li>Plot and save the results of an Excited State (e.g. TD-DFT)
                calculation.
            </li>
        </ul>
    </Container>
);

export default Description;
