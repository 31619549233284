import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

const NotFound = () => (
    <Container className={'mt-5'}>
        <Card border={'danger'}>
            <Card.Header style={{color: '#ffffff', backgroundColor: '#dc3545'}}>
                <h3>Page Not Found</h3>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <p>Unable to find the page for which you are looking.</p>
                    <p>
                        If you think this is a mistake, please
                        contact <a href="emailto:webmaster@qmforge.net">webmaster@qmforge.net</a>.
                    </p>
                </Card.Text>
            </Card.Body>
        </Card>
    </Container>
)

export default NotFound;
