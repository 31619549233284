import Card from 'react-bootstrap/Card';
import {Placeholder} from "react-bootstrap";

const PlaceholderProduct = () => {
    return (
        <Card className={'bg-light mb-3 text-center'}>
            <Card.Header>
                <Placeholder.Button variant="secondary" xs={2} style={{height: 80, textAlign: 'center'}}/>
            </Card.Header>
            <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={3} size="lg"/><br/>
                    <Placeholder xs={3} size="lg"/>
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={1}/>
                </Placeholder>
                <Placeholder.Button variant="primary" xs={1} />
            </Card.Body>
        </Card>
    );
}

export default PlaceholderProduct;
