import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from "axios";

import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import config from '../config';
import CheckoutHeader from "./checkout/CheckoutHeader";
import CheckoutItem from "./checkout/CheckoutItem";
import CheckoutCard from "./checkout/CheckoutCard";
import CheckoutButton from "./checkout/CheckoutButton";
import {Redirect} from "react-router-dom";

const nameError = 'Please enter your name.';
const emailError = 'Please provide a valid email.'

const schema = yup.object({
    name: yup.string().required(nameError),
    email: yup.string().email(emailError).required(emailError)
}).required();

const Checkout = (props) => {
    const { buying: item, setBuying } = props;

    const [succeeded, setSucceeded] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [orderId, setOrderId] = useState();
    const [expireDate, setExpireDate] = useState();

    const stripe = useStripe();
    const elements = useElements();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        if (!!item) {
            axios.post(`https://${config.api}/buy`, item)
                .then(response => {
                    setClientSecret(response.data.clientSecret)
                });
        }

    }, [item]);

    const onSubmit = async data => {
        console.log('Submitting w/', data)
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        console.log('Result for submit:', JSON.stringify(payload))
        if (payload.error) {
            setStripeError(payload.error.message);
            setProcessing(false);
        } else {
            setStripeError(null);
            setProcessing(false);
            setSucceeded(true);
            axios.post(`https://${config.api}/fulfill`, {
                item,
                name: data.name,
                email: data.email,
                payment_id: payload.paymentIntent.id,
                status: payload.paymentIntent.status
            }).then((response) => {
                setExpireDate(response.data.expire_date);
                setOrderId(response.data.order_id);
            })
        }
    }

    return (
        orderId
            ? <Redirect to={{ pathname: `/download/${orderId}`, state: { item, expireDate }}} />
            : <Container className={'text-center pt-5'}>
                <Modal show={!!item} onHide={() => setBuying(undefined)}>
                    <CheckoutHeader item={item} />
                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <CheckoutItem errors={errors}
                                          register={register}
                                          type={'name'}
                                          defaultValue={'Name'}
                                          defaultError={'nameError'}
                            />
                            <CheckoutItem errors={errors}
                                          register={register}
                                          type={'email'}
                                          defaultValue={'Email'}
                                          defaultError={emailError}
                            />
                            <CheckoutCard stripeError={stripeError} />
                            <CheckoutButton processing={processing || succeeded} price={item?.price} />
                        </Form>
                    </Modal.Body>
                </Modal>
            </Container>
    )
}

export default Checkout;
