import axios from "axios";
import config from "../config";

const handleDownload = (orderId, setStatus) => {
    axios.get(`https://${config.api}/download?order_id=${orderId}`)
        .then((response) => {
            console.log(JSON.stringify(response));
            window.location.replace(response.data.url)
        })
        .catch((error) => {
            if (error.response) {
                console.log('Problem downloading error:', error.response.status)
                setStatus(error.response.status)
            } else if (error.request) {
                console.log('Error making request', error.request)
                setStatus(404);
            } else {
                console.log('Unknown error:', error.message)
                setStatus(404);
            }
        })
};

export default handleDownload;
